import "./App.css";
import { Todo } from "./Components/Todo";

function App() {
  return (
    <Todo/>
  )
}

export default App;
